/** Dependencies **/
@import '~normalize.css/normalize.css';
@import 'material-theme';

/** Core **/
@import 'core';
@import 'functions';

@tailwind utilities;

/** Tailwind CSS responsive variants of classes **/
@layer utilities {
	.span1 {
		width: calc(100% * (1 / 12));
	}

	.span2 {
		width: calc(100% * (2 / 12));
	}

	.span3 {
		width: calc(100% * (3 / 12));
	}

	.span4 {
		width: calc(100% * (4 / 12));
	}

	.span5 {
		width: calc(100% * (5 / 12));
	}

	.span6 {
		width: calc(100% * (6 / 12));
	}

	.span7 {
		width: calc(100% * (7 / 12));
	}

	.span8 {
		width: calc(100% * (8 / 12));
	}

	.span9 {
		width: calc(100% * (9 / 12));
	}

	.span10 {
		width: calc(100% * (10 / 12));
	}

	.span11 {
		width: calc(100% * (11 / 12));
	}

	.span12 {
		width: calc(100% * (12 / 12));
	}

	.top-margin-spacing8 {
		margin-top: spacing(8);
	}
}
